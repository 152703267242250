import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/amira-jade-hodes.jpg";
  const fullName = "Amira Jade Hodes";
  const zoomUrl =
    "https://us02web.zoom.us/j/81505630725?pwd=N2poNlNRVUVOc3NDenVVR1NkbXRrdz09";
  const zoomPhoneNumber = "(646) 558-8656 ";
  const zoomMeetingId = "815 0563 0725";
  const zoomMeetingPasscode = "159472";
  const birthDate = "July 14, 1992";
  const deathDate = "November 22, 2020";
  const memorialDate = "Sunday December 6th, 2020";
  const memorialTime = "11am EST";
  const inviteText =
    "Amira passed away on November 22nd, and we will miss her terribly. She was taken from us far too soon, but lived and loved enough to have us. Please join us in celebrating her life in a virtual ceremony.";
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fbo6hwitz";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rv7hfn04gviq9iuai4u9s35p2hstq2bs51e9eo1c";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    >
      <h4>In Lieu of Flowers</h4>
      <p>
        Please donate to{" "}
        <a
          href="https://fibrofoundation.org/giving/"
          target="_blank"
          rel="noreferrer"
        >
          The Fibro Foundation
        </a>{" "}
        or{" "}
        <a
          href="http://rush.convio.net/AHodes"
          target="_blank"
          rel="noreferrer"
        >
          Rush University
        </a>
      </p>
    </StoryPage>
  );
};

export default ThisPage;
